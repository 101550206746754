<template>
  <FileInput
    class='vue-fine-uploader-gallery-file-input-container'
    :uploader="uploader"
    :multiple="multiple"
  >
    <span class='vue-fine-uploader-gallery-file-input-content'>
      <slot>
        <UploadIcon class='vue-fine-uploader-gallery-file-input-upload-icon' />
        选择文件
      </slot>
    </span>
  </FileInput>
</template>

<script>
/* eslint-disable */
  import FileInput from '../file-input'
  import UploadIcon from './upload-icon'
  export default {
    components: {
      FileInput,
      UploadIcon
    },
    props: {
      uploader: {
        type: Object,
        required: true
      },
      multiple: {
        type: Boolean,
        default: true
      }
    }
  }
</script>
/* eslint-disable */
import { authRequest } from './auth';

const applycloudresource = (cloudappbody) => {
  const extraParameters = cloudappbody;
  return authRequest.put('/api/cloudapp/' + cloudappbody.id + '/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { applycloudresource }; // eslint-disable-line import/prefer-default-export

/* eslint-disable */
import { authRequest } from './auth';

const deletecloudapp = (cloudappbody) => {
  const extraParameters = cloudappbody;
  return authRequest.delete('/api/cloudapplications/' + cloudappbody.id + '/', extraParameters)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};

export { deletecloudapp }; // eslint-disable-line import/prefer-default-export

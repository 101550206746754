<template>
  <div class="cloudapplist-container">
    <div class="filter-container">
      <!-- <el-input
        v-model="listQuery.title"
        placeholder="类别"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      /> -->
      <!-- <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      > -->
      <!-- 查找
      </el-button> -->
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
      >
        新增
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.imagefiles" stripe>
            <el-table-column align="center" label="File Name" min-width="100">
              <template slot-scope="{ row }">
                <span>{{ row.file_name }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="UUID" min-width="100">
              <template slot-scope="{ row }">
                <span>{{ row.file_uuid }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Size" min-width="100">
              <template slot-scope="{ row }">
                <!-- <span>{{ row.file_size }}</span> -->
                <span v-html="byteformat(row.file_size)"></span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="ID"
        type="index"
        width="50">
      </el-table-column>
      <!-- <el-table-column align="center" label="ID" width="80">
        <template slot-scope="{ row }">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column> -->

      <el-table-column align="center" label="Application Name" min-width="100">
        <template slot-scope="{ row }">
          <span>{{ row.app_name }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="App Description" min-width="200">
        <template slot-scope="{ row }">
          <span>{{ row.app_description }}</span>
        </template>
      </el-table-column>

      <el-table-column
        class-name="status-col"
        align="center"
        label="Status"
        width="110"
      >
        <template slot-scope="{ row }">
          <el-tag :type="row.app_status | statusFilter">
            {{ row.app_status }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column width="180px" align="center" label="Instance Deployed">
        <template slot-scope="{ row }">
          <span>{{ row.app_resourceid }}</span>
        </template>
      </el-table-column>

      <el-table-column min-width="100" align="center" label="Access URL">
        <template slot-scope="{ row }">
          <span>{{ row.app_accessurl }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column
        label="Actions"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button
            v-if="row.app_status === 'stopped' || row.app_status === 'deployed'"
            size="mini"
            type="success"
            @click="handleModifyStatus(row, 'running')"
          >
            Start
          </el-button>
          <el-button
            v-if="row.app_status === 'running'"
            size="mini"
            type="danger"
            @click="handleModifyStatus(row, 'stopped')"
          >
            Stop
          </el-button>
        </template>
      </el-table-column> -->

      <el-table-column
        label="Actions"
        align="center"
        width="300"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button round type="primary" size="mini" @click="handleUpdate(row)">
            修改
          </el-button>
          <el-button round type="primary" size="mini" @click="handleApply(row)">
            申请
          </el-button>
          <el-button
            round
            size="mini"
            type="info"
            @click="handleDeploy(row)"
          >
            部署
          </el-button>
          <el-button
            round
            size="mini"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" v-if="dialogFormVisible">
      <el-form
        ref="cloudappForm"
        :model="cloudappform"
        label-position="left"
        label-width="100px"
        style="width: 90%; margin-left: 20px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="应用名称">
              <el-input v-model="cloudappform.app_name" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="访问URL">
              <el-input v-model="cloudappform.app_accessurl" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="应用描述">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="cloudappform.app_description"
            /></el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="容器编排文件">
            <prism-editor
              class="my-editor"
              v-model="cloudappform.app_composefile"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="镜像文件">
            <Gallery :uploader="uploader" />
          </el-form-item>
        </el-row>

      </el-form>

      <el-divider></el-divider>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel"> 取消 </el-button>
        <el-button
          type="primary"
          @click="dialogStatus === 'create' ? createData() : updateData()"
        >
          确认
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="执行结果" :visible.sync="consoleVisible" v-if="consoleVisible">
      <div class="col s12 offset-m2 m8">
        <v-console style="height: 30vh"  />
      </div>

      <el-divider></el-divider>

      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="handleDone"
        >
          关闭
        </el-button>
      </div>
    </el-dialog>

    <!-- <el-divider></el-divider> -->

    <!-- <div class="col s12 offset-m2 m8">
      <v-console style="height: 60vh" :content="content" />
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import { fetchapplist } from "../../services/api/fetchapplist";
import { mapGetters } from "vuex";

import Console from "@/components/console";
// import { fetchcmdhistory } from "../../services/api/fetchcmdhistory";
// import { operatecloudapp } from "../../services/api/operatecloudapp";
import { createcloudapp } from "../../services/api/createcloudapp";
import { updatecloudapp } from "../../services/api/updatecloudapp";
import { deletecloudapp } from "../../services/api/deletecloudapp";
import { applycloudresource } from "../../services/api/applycloudresource";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "@/components/Uploader/gallery";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

import sseEConsole from "@/components/sse-econsole";

const cmdMap = {
  running: "start",
  stopped: "stop",
};

export default {
  name: "cloudapplist",
  content: "",
  cmdresult: 0,
  computed: {
    ...mapGetters(["name"]),
  },
  components: {
    Gallery,
    PrismEditor,
    "v-console": sseEConsole,
  },

  filters: {
    statusFilter(status) {
      const statusMap = {
        running: "success",
        stopped: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    var header_auth = `Bearer ${window.localStorage.getItem("access_token")}`;
    const uploader = new FineUploaderTraditional({
      options: {
        debug: false,
        request: {
          endpoint: `${process.env.VUE_APP_API_HOST}/api/upload/endpoint`,
          customHeaders: {
            Authorization: header_auth,
          },
        },
        chunking: {
          enabled: true,
          partSize: 1000000,
        },
        deleteFile: {
          enabled: true, // defaults to false
          endpoint: `${process.env.VUE_APP_API_HOST}/api/upload/endpoint`,
        },
      },
    });


    return {
      uploader,

      list: null,

      listLoading: false,
      details: null,
      content: "",
      operateform: {
        id: "",
        cmd: "",
      },
      
      cloudappform: {
        app_name: "",
        app_description: "",
        app_accessurl: "",
        app_region: "beijing",
        app_status: "",
        app_resourceid: "",
        app_composefile: "",
        imagefiles: [],
      },

      // composefile: "",

      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "Edit",
        create: "Create",
      },

      consoleVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      fetchapplist()
        .then((data) => {
          this.list = data;
          // console.log(this.list)
        })
        .catch((error) => {
          alert(error);
        });
    },
    byteformat(size){
      let formattedSize, formattedUnits
      if (size < 1e+3) {
        formattedSize = size
        formattedUnits = 'B'
      } else if (size >= 1e+3 && size < 1e+6) {
        formattedSize = (size / 1e+3).toFixed(2)
        formattedUnits = 'KB'
      } else if (size >= 1e+6 && size < 1e+9) {
        formattedSize = (size / 1e+6).toFixed(2)
        formattedUnits = 'MB'
      } else if (size >= 1e+9 && size < 1e+12) {
        formattedSize = (size / 1e+9).toFixed(2)
        formattedUnits = 'GB'
      } else {
        formattedSize = (size / 1e+12).toFixed(2)
        formattedUnits = 'TB'
      }

      return formattedSize + ' ' + formattedUnits;

    },
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
    resetUploader(){
      // this.uploader.methods.reset();

      // const statusEnum = this.uploader.qq.status;
      // const uploads = this.uploader.methods.getUploads({
      //   status: statusEnum.UPLOAD_SUCCESSFUL,
      // });
      // console.log(uploads);
      // uploads.forEach(function (upload) {
      //   console.log(upload);
      //   this.uploader.methods.deleteFile(upload.id);
      // }, this);

      // this.$forceUpdate()
      
    },
    setImageFiles(){
      const statusEnum = this.uploader.qq.status;
      const uploads = this.uploader.methods.getUploads({
        status: statusEnum.UPLOAD_SUCCESSFUL,
      });

      this.imagefiles = [];

      uploads.forEach(function (upload) {
        var imagefile = { file_name: upload.name, file_uuid: upload.uuid, file_size: upload.size };
        this.imagefiles.push(imagefile);
      }, this);

      this.cloudappform.imagefiles = this.imagefiles;
    },
    handleCreate() {
      // this.$router.push({path: '/cloudappdeploy/index'});
      this.uploader.methods.reset();

      this.cloudappform = {
        app_name: "",
        app_description: "",
        app_region: "beijing",
        app_status: "created",
        app_accessurl: "",
        app_composefile: "",
      };
      // this.composefile = "";
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
    },
    createData() {
      this.setImageFiles();

      // this.cloudappform.app_composefile = this.composefile;

      createcloudapp(this.cloudappform)
        .then((data) => {
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            this.cloudappform.id = data.msg;
            this.list.unshift(this.cloudappform);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });

      this.dialogFormVisible = false;
    },
    handleCancel(){
      this.uploader.methods.reset();

      this.cloudappform.app_name = "";
      this.cloudappform.app_description = "";
      this.cloudappform.app_accessurl = "";
      this.cloudappform.app_composefile = "";

      // this.composefile = "";

      this.dialogFormVisible = false
    },
    handleDelete(row) {
      this.cloudappform = Object.assign({}, row); // copy obj

      deletecloudapp(this.cloudappform)
        .then((response) => {
          var res = response.status;

          // no data returned for status 204.
          if (res === 204) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            const index = this.list.findIndex(
                (v) => v.id === row.id
              );

            this.list.splice(index, 1);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    handleApply(row) {
      this.cloudappform = Object.assign({}, row); // copy obj
      this.cloudappform.app_status = "applying";

      var index = this.list.findIndex(
        (v) => v.id === this.cloudappform.id
      );
      this.list.splice(index, 1, this.cloudappform);

      applycloudresource(this.cloudappform)
        .then((data) => {
          var res = data.code;
          if (res === 0) {
            var instancename = data.msg
            this.$message({
              message: "操作成功",
              type: "success",
            });

            this.cloudappform.app_status = "applied"
            this.cloudappform.app_resourceid = instancename

            index = this.list.findIndex(
              (v) => v.id === this.cloudappform.id
            );
            this.list.splice(index, 1, this.cloudappform);

          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });

    },
    handleDeploy(row){

      this.consoleVisible = true;

      this.cloudappform = Object.assign({}, row); // copy obj
      this.cloudappform.app_status = "deploying";

      var index = this.list.findIndex(
        (v) => v.id === this.cloudappform.id
      );
      this.list.splice(index, 1, this.cloudappform);

      updatecloudapp(this.cloudappform)
        .then((data) => {
          var res = data.code;
          if (res === 0) {
            var instancename = data.msg
            this.$message({
              message: "操作成功",
              type: "success",
            });

            this.cloudappform.app_status = "deployed"
            this.cloudappform.app_resourceid = instancename

            index = this.list.findIndex(
              (v) => v.id === this.cloudappform.id
            );
            this.list.splice(index, 1, this.cloudappform);

          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });

    },
    handleDone(){
      this.consoleVisible = false;
    },
    handleUpdate(row) {
      this.cloudappform = Object.assign({}, row); // copy obj
      this.dialogStatus = "update";
      this.dialogFormVisible = true;

      this.uploader.methods.reset();

      // var uploaderFiles = this.uploader.methods.getUploads();
      // console.log(uploaderFiles);
      // console.log(this.cloudappform);
      
      const imagefiles = this.cloudappform.imagefiles;
      var initialFiles = [];
      imagefiles.forEach(function (imagefile) {
        var initialFile = { name: imagefile.file_name, uuid: imagefile.file_uuid, size: imagefile.file_size };
        initialFiles.push(initialFile);
      }, this);
      this.uploader.methods.addInitialFiles(initialFiles);
      
      // this.$router.push({path: '/cloudappdeploy/index'});
    },
    updateData() {
      // const statusEnum = this.uploader.qq.status;
      // const uploads = this.uploader.methods.getUploads({
      //   status: statusEnum.UPLOAD_SUCCESSFUL,
      // });

      // this.imagefiles = [];

      // uploads.forEach(function (upload) {
      //   var imagefile = { file_name: upload.name, file_uuid: upload.uuid, file_uuid: upload.size };
      //   this.imagefiles.push(imagefile);
      // }, this);

      // this.cloudappform.imagefiles = this.imagefiles;
      this.setImageFiles();

      console.log(this.cloudappform)

      updatecloudapp(this.cloudappform)
        .then((data) => {
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            const index = this.list.findIndex(
              (v) => v.id === this.cloudappform.id
            );
            this.list.splice(index, 1, this.cloudappform);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });

      this.dialogFormVisible = false;

    },
    // handleModifyStatus(row, status) {
    //   // console.log(row);
    //   this.operateform.id = row.id;
    //   this.operateform.cmd = cmdMap[status];
    //   operatecloudapp(this.operateform)
    //     .then((data) => {
    //       this.content = data.msg;
    //       this.cmdresult = data.code;

    //       if (this.cmdresult === 1) {
    //         this.$message({
    //           message: "操作成功",
    //           type: "success",
    //         });
    //         row.app_status = status;
    //       } else {
    //         this.$message({
    //           message: "操作失败",
    //           type: "error",
    //         });
    //         row.app_status = status;
    //       }
    //     })
    //     .catch((error) => {
    //       alert(error);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.cloudapplist {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}

.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;

  color: #ccc;

  // margin: 5px, 30px;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

import { authRequest } from './auth';

const createcloudapp = (cloudappbody) => {
  const extraParameters = cloudappbody;
  return authRequest.post('/api/cloudapp/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { createcloudapp }; // eslint-disable-line import/prefer-default-export

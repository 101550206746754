<template>
  <div class="console" v-html="html"></div>
</template>

<script>
/* eslint-disable */
import AnsiUp from "ansi_up";
import { fetchcmdhistory } from "../services/api/fetchcmdhistory";

export default {
  name: "console",
  // props: ["socket"],
  props: ["content"],
  data() {
    return {
      ansi: undefined,
      // content: "",
    };
  },
  computed: {
    html() {
      return this.ansi.ansi_to_html(this.content).replace(/\n/gm, "<br>");
    },
  },
  watch: {
    // socket() {
    //   this.socket.on("data", (data) => {
    //     this.content += data;
    //   });
    //   this.socket.send("ready");
    // },
  },
  methods: {
    async getList() {
      // fetchcmdhistory()
      //   .then((data) => {
      //     this.content = data;
      //   })
      //   .catch((error) => {
      //     alert(error);
      //   });
    },
  },
  created() {
    // this.getList();
  },
  beforeMount() {
    this.ansi = new AnsiUp();
  },
  mounted() {
    // window.shell = this;
  },
  updated() {
    this.$el.scrollTop = this.$el.scrollHeight;
  },
};
</script>

<style lang="scss" scoped>
.console {
  font-family: "roboto mono", monospace;
  // font-family: "DejaVu Sans Mono", monospace;
  font-size: 16px;
  text-align: left;
  background-color: black;
  color: #fff;
  overflow-y: auto;
  padding: 6px 5px 6px 15px;
  white-space: pre;
}
</style>

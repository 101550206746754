import { authRequest } from './auth';

const fetchapplist = async () => {
  const extraParameters = {};
  return authRequest.get('/api/cloudapplications/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { fetchapplist }; // eslint-disable-line import/prefer-default-export
